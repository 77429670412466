import React from "react"

import {graphql, Link, useStaticQuery} from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import {getPageUrl} from '../lib/helpers'

import Layout from '../containers/layout'

import BlogPostPreviewList from '../components/blog-post-preview-list'
import Container from '../components/container'
import HomepageBottom from "../components/homepage-bottom"
import HomepageHero from '../components/homepage-hero'
import RelatedPages from '../components/related-pages'
import SearchBar from '../components/search-bar'
import MetaSchemaSEO from '../components/seo';

import * as styles from './index.module.css'
import * as styles_preview from '../components/blog-post-preview-grid.module.css'

const old = `
query {
  indexQuery: strapi{ siteSetting {
    id
    site_name
    default_seo_description
    featured_pages {
      id
      title
      slug
      primary_topic
      page_type {
        name
        slug_prefix
      }
      parent {
        title
        title_short
        slug
        page_type {
          name
          slug_prefix
        }
      }
      site_section: page_type{
        name
        slug_prefix
      }
      main_image {
        url
        caption
        alternativeText
        localFile{
          childImageSharp{
            gatsbyImageData(
              width: 400,
              aspectRatio: 1,
              quality: 70,
              transformOptions: {cropFocus:ENTROPY})
          }
        }
      }
    }
  }}
  strapi {
    pages(
      sort: "publishedAt:desc"
      limit: 1
      publicationState: LIVE
      where: { is_featured: "true" }
    ) {
      id
      is_featured
      slug
      page_type {
        name
        slug_prefix
      }
      site_section: page_type{
        name
        slug_prefix
      }
      title
      title_short
      primary_topic
      publishedAt
      parent {
        title
        title_short
        slug
        page_type {
          name
          slug_prefix
        }
      }
      page_value
      main_image {
        url
        alternativeText
        caption
        localFile{
          childImageSharp{
            gatsbyImageData(
              width: 1000,
              aspectRatio: 2,
              transformOptions: {cropFocus:ENTROPY}
            )
          }
        }
      }
    }
  }
  featuredTopPages: strapi {
    pages(
      sort: "publishedAt:desc"
      limit: 4
      publicationState: LIVE
      where: { is_featured: "true" }
      start: 1
    ) {
      id
      is_featured
      slug
      primary_topic
      page_type {
        name
        slug_prefix
      }
      site_section: page_type{
        name
        slug_prefix
      }
      title
      title_short
      primary_topic
      publishedAt
      parent {
        title
        title_short
        slug
        page_type {
          name
          slug_prefix
        }
      }
      page_value
      main_image{
        url
        alternativeText
        caption
        localFile{
          childImageSharp{
            gatsbyImageData(
              width: 300,
              aspectRatio: 1,
              transformOptions: {cropFocus:ENTROPY}
            )
          }
        }
      }
    }
  }
  newsPages2: strapi{ pages(
    sort: "createdAt:desc"
    limit: 16
    start: 5
    publicationState: LIVE
    ) {
        id
        slug
        primary_topic
        page_type {
          name
          slug_prefix
        }
        site_section: page_type{
          name
          slug_prefix
        }
        title
        excerpt
        publishedAt
        parent {
          title
          title_short
          slug
          page_type {
            name
            slug_prefix
          }
        }
        main_image{
          url
          alternativeText
          caption
          localFile{
            childImageSharp{
              gatsbyImageData(
                width: 400,
                aspectRatio: 1,
                transformOptions: {cropFocus:ENTROPY}
              )
            }
          }
        }
      }
    },
  newsPages: strapi{ pages(
    sort: "createdAt:desc"
    limit: 5
    publicationState: LIVE
    ) {
        id
        slug
        primary_topic
        page_type {
          name
          slug_prefix
        }
        site_section: page_type{
          name
          slug_prefix
        }
        title
        authors {
          id
          name
          slug
          main_image {
            url
            caption
            alternativeText
            localFile{
              childImageSharp{
                gatsbyImageData(
                  quality: 80,
                  width: 50,
                  aspectRatio: 1,
                  transformOptions: {cropFocus:ENTROPY})
              }
            }
          }
        }
        excerpt
        publishedAt
        parent {
          title
          title_short
          slug
          page_type {
            name
            slug_prefix
          }
        }
        main_image{
          url
          alternativeText
          caption
          localFile{
            childImageSharp{
              gatsbyImageData(
                width: 400,
                aspectRatio: 1,
                transformOptions: {cropFocus:ENTROPY}
              )
            }
          }
        }
      }
    },
    relatedPages: strapi{ pages(
      sort: "createdAt:desc"
      limit: 4
      publicationState: LIVE
      where: { is_featured: "true" }
      start: 5
      ) {
          id
          slug
          page_type {
            name
            slug_prefix
          }
          site_section: page_type{
            name
            slug_prefix
          }
          title
          excerpt
          publishedAt
          parent {
            title
            title_short
            slug
            page_type {
              name
              slug_prefix
            }
          }
          main_image{
            url
            alternativeText
            caption
            localFile{
              childImageSharp{
                gatsbyImageData(
                  width: 300,
                  aspectRatio: 1,
                  transformOptions: {cropFocus:ENTROPY}
                )
              }
            }
          }
        }
      }
}`
export const query = graphql`
  query {
    strapiSiteSetting {
      id
      site_name
      description: default_seo_description
    }
    strapiHomepage {
      hero_page {
        slug
        title
        primary_topic
        page_type {
          name
          slug_prefix
        }
        main_image{
          url
          alternativeText
          caption
          localFile{
            childImageSharp{
              gatsbyImageData(
                width: 1000,
                aspectRatio: 1.8,
                quality: 70,
                transformOptions: {cropFocus:ENTROPY}
              )
            }
          }
        }
      }
      featured_pages {
        id
        title
        slug
        excerpt
        main_image {
          url
          alternativeText
          caption
          localFile{
            childImageSharp{
              gatsbyImageData(
                width: 200,
                aspectRatio: 0.75,
                transformOptions: {cropFocus:ENTROPY}
              )
            }
          }
        }
      }
    }


    news: allStrapiPage(
      limit: 5
      ) {
      nodes {
        id
        is_featured
        slug
        title
        excerpt
        primary_topic
        page_type {
          name
          slug_prefix
        }
        main_image{
          url
          alternativeText
          caption
          localFile{
            childImageSharp{
              gatsbyImageData(
                width: 300,
                aspectRatio: 1,
                transformOptions: {cropFocus:ENTROPY}
              )
            }
          }
        }
      }
    }

    recent: allStrapiPage(
      limit: 20
      ) {
      nodes {
        id
        is_featured
        slug
        title
        excerpt
        primary_topic
        page_type {
          name
          slug_prefix
        }
        main_image{
          url
          alternativeText
          caption
          localFile{
            childImageSharp{
              gatsbyImageData(
                width: 300,
                aspectRatio: 1,
                transformOptions: {cropFocus:ENTROPY}
              )
            }
          }
        }
      }
    }
  }
`

// markup
const IndexPage = () => {
  const data = useStaticQuery(query);

  const heroPage = data?.strapiHomepage.hero_page
  const postNodes = data?.news.nodes
  const recentPages = data?.recent.nodes
  const featuredSitePages = data?.strapiHomepage?.featured_pages


  return (
    <Layout>
      <MetaSchemaSEO/>
      <HomepageHero></HomepageHero>
      <Container>
          <div className={styles.hero}>
            {heroPage &&
              <Link to={getPageUrl(heroPage)} key="hero_page">
                <h2>{heroPage.title}</h2>
                { heroPage.main_image && 
                <GatsbyImage 
                  className={styles.heroImage} 
                  image={getImage(heroPage.main_image.localFile)} 
                  alt={heroPage.title} />
                }
              </Link>
            }
          </div>
        { featuredSitePages && (
          <div className={styles.featuredPages}>
            <ul className={styles_preview.grid_two}>
              <li>

                <BlogPostPreviewList
                  nodes={featuredSitePages.slice(0,1)}
                />
              </li>
              <li>

                <BlogPostPreviewList
                  nodes={featuredSitePages.slice(1,2)}
                />
              </li>
            </ul>
          </div>
        )
        }
        { process.env.CONTENTCURATOR_SITESEARCH_CREDENTIALS && 
          <SearchBar></SearchBar>
        }

      {postNodes && (
        <>
          <BlogPostPreviewList
            nodes={postNodes}
          />
        </>
      )}
        </Container>
      <Container>
        { process.env.CONTENTCURATOR_SITESEARCH_CREDENTIALS && 
          <SearchBar></SearchBar>
        }

      </Container>

      {featuredSitePages && featuredSitePages.length > 0 && (<RelatedPages nodes={featuredSitePages}></RelatedPages>)}
      <Container>
        {recentPages && recentPages.length > 0 &&
          <div className={styles.latestPages}>
            <span>Recently Published</span>
            <ul className={styles.listPages}>
                {recentPages.map(post => (
                    <li key={post.id}>
                      <Link to={getPageUrl(post)}>{post.title}</Link>
                    </li>
                ))}
            </ul>
          </div>
        }
      </Container>
      <HomepageBottom></HomepageBottom>
    </Layout>
  )
}

export default IndexPage
